import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './Archive.less';

const Archive = () => {
	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - Archive Documents
				</title>
			</Helmet>
			<div className="box-content box">
				<Link to="/archive/attitude">Attitude</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/at-swim-two-birds">At Swim Two Birds</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/banks-of-the-boro">Banks of the Boro</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/buckram-back">Buckram Back: The Country Dancing Master</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/country-dance">Country Dance and Quadrille</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/dance-light">Dance Light For My Heart Lies Under Your Feet</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/evils-of-dancing">Evils of Dancing</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/ecstasy">Ecstasy in Eighteenth-Century Kildare?</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/lanigans-ball">Lanigan's Ball</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/book-review">Review of The Complete Guide to Irish Dance</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/dance-at-marley">The Dance at Marley</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/dance-halls">The Dance Halls</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/ould-irish-jig">The Ould Irish Jig</Link>
			</div>
			<div className="box-content box">
				<Link to="/archive/trenchmore">Trenchmore</Link>
			</div>
		</div>
	);
};

export default Archive;
