import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';

const article = `
# Review of The Complete Guide to Irish Dance by Helen Brennan / Appletree Press
### _Frank Whelan_

The title of this book is awesome - "The Complete Guide to Irish Dance". It promises a great deal. Given the dearth of books on the topic, at least until recently, the field is relatively open.

The writer is obviously an enthusiast and that is a good thing in itself. Dance is his passion. Given that, it seems a shame that his work is so untouched by the body of scholarship now available, from writers like Breathnach, Brennan, Cullinane, Donnelly, Hammond, Lynch, Moylan, Murphy et al.

In the early history section of this book, we are back in the mists of time with the hunter-gatherers, tree-worshippers and animal-dancers - a veritable dance anthropologist's nightmare. In the following sections, we are conveyed on a lightning-tour of various aspects of Irish music and dance life.

We hear that the legendary tribe, the Tuatha Dé Danann introduced music into Ireland; we are even given the exact year - 1600 B.C.! The book is studded with such nuggets. In the section dealing with social factors and Irish dance, the author spends some time developing a theory of foreign influence on Irish dance via sailors and soldiers in an area such as Cork city. We feel he is about to make some point, expound some theory or at least draw some conclusion but instead, we find ourselves in a dancing cul-de-sac. Once again, opinion takes the place of concrete fact.

It is depressing that the author feels the need to return yet again to the céilí dance/set dance divide. In this section, his lack of knowledge of real traditional practice is betrayed time and again. If we followed his line of argument, we would see counties Clare and Kerry as the only location of sets prior to the revival in the 1980s. In fact, it has been conclusively proven that the sets flourished in every part of Ireland, both rural and urban, in the late 19th century and continued to be danced well into the 20th century. I myself interviewed two staunch Gaelic Leaguers in the town of Drogheda who learned céilí dancing c.1917 but continued to dance sets at local house-dances where they were the popular social dances of the period.

It is about time that all talk of British sappers, Napoleonic veterans and culturally-contaminated soldiers and sailors being responsible for the introduction of sets and other 'foreign' dance was consigned to the dustbin of history where it belongs. In fact, the sets (originally 'suites de quadrilles') were fashionable ballroom dances of the early 1800s. They moved gradually via the dancing masters and local dance enthusiasts to the dance-locations of the ordinary people who already danced 4- and 8-hand jigs and reels and thus would find the shape of the various sets easy to assimilate.

It is ironic that the author once again seeks to maintain that his ancestors danced no sets, but only céilí dances. His use of the date 1860 as a cultural cut-off point is interesting as this was, in fact, many years before the term 'céilí-dancing', let alone its practice, became current. He praises highly and deservedly the powerful London branch of the Gaelic League which was the prime mover in the introduction of dance to the League in general. The great Fionán Mac Coluim, himself a member of this branch, documents in detail the genesis of the céilí dance movement and recounts how, at the first céilí in Bloomsbury in 1897, the main group dance was the quadrille performed to Irish airs in honour of the occasion. The gradual discovery of traditional Irish dances by means of the organisation of collecting-trips to Ireland resulted in an attempt to create a 'canon' of Irish dance The basis of this early documentation was haphazard and geographically-skewed and the process of selection or rejection of dances as genuinely Irish was anything but logical and rational. Indeed, reasoned debate was well-nigh impossible, given the white-hot passions which were unleashed. The legacy of those turbulent times is still to be seen in this book with its bias towards the world of céilí-dancing and its complete lack of consideration, nay, even of awareness of a whole body of traditional Irish dance outside the ambit of the "Coimisiúin" and all its works and pomps.

The dances listed and described in the main section of the book are culled primarily from the handbooks of "Ár Rincí Fóirne" - the publications of An Coimisiúin Le Rincí Gaelacha, with a handful from other sources. Useful diagrams are a welcome addition to the style of A.R.F. and there are several carefully-composed photographs illustrating basic steps.These aside, this book has little new to add to the area of dance scholarship in Ireland. The world awaits "The Complete Guide to Irish Dance ". This is, assuredly, not it.
`;

const BookReview = () => {
	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - Review of The Complete Guide to Irish Dance
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						Review of The Complete Guide to Irish Dance
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default BookReview;
