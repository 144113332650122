import React from 'react';
import { Helmet } from 'react-helmet';
import { LinkContainer } from 'react-router-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ReactMarkdown from 'react-markdown';

const article = `
# At Swim Two Birds
## _Flann O'Brien_

_Biographical reminiscence, part the seventh:_

I recall that I went into my uncle's house about nine p.m. one evening in the early spring, the sharp edge of my perception dulled somewhat by indulgence in spirituous liquors. I was standing in the middle of the dining-room floor before I had properly adverted to my surrounding. The faces I found there were strange and questioning. Searching among them, I found at last the features of my uncle.

_Nature of features:_ Red, irregular, coarse, fat. He was situated in a central position in the midst of four others and looked out from them in my direction in a penetrating attentive manner. I was my way backwards towards the door when he said to me:

No need to go. Gentlemen, my nephew. I think we require a secretary. Take a seat.

After this I heard a murmur of polite felicitation. It was represented that my continued presence was a keen source of pleasure to the company without exception. I sat down at the table and took my blue pencil from a chest-pocket. My uncle studied a black note-book for a moment and then pushed it across to me and said:

I think that should be enough.

I took the book and read the legend inscribed on the front page in the square unambiguous writing of my uncle. Nature of legend: Eighteen loaves. Two pan-loaves (?one pan). Three
pounds cheese. Five pounds cooked ham. Two pounds tea (One and four). Tin floor-powder. Fancy cakes 2d. and 3d. (?4d.). Eighteen rock-buns. Eight pounds butter. Sugar, milk (?each bring supply?). Rosin. Bottle D.W.D???????????. Hire of crockery &pound;1. ?Breakages Speak re necessity care. ?Lemonade. Say &pound;5.

My uncle made an urgent noise with the case of his spectacles.

You were saying, Mr. Connors...? he said.

Ah, yes, said Mr. Connors.

A big loose man to my left drew himself together and braced his body for the ordeal of utterance. He wore on the upper lip a great straggling moustache and heavy tired eyes moved slowly as if belated in adjustment to his other features. He struggled to an attitude of upright attention. Now I think it's a great mistake to be too strict, he said. We must
make allowances. One old-time waltz is all I ask. It's as Irish as any of them, nothing foreign about the old-time waltz. We must make allowances.

The Gaelic League ...

I don't agree, said another man.

My uncle gave a sharp crack on the table.

Order, Mr. Corcoran, he said in reprimand, order if you please. Mr. Connors has the floor. This is a Committee meeting. I'm sick sore and tired saying this is a Committee meeting. After all there is such a thing as Procedure, there is such a thing as Order, there is such a thing as doing things in the right way. Have you a Point of Order, Mr. Corcoran?

I have, said Mr. Corcoran. He was tall and thin and fair. I found that his face was known to me. His hair was sparse and sandy. Very well. If you have a Point of Order, well and good. Well and good if you have a Point of Order. Proceed.

Eh? said Mr. Corcoran.

Proceed. Continue.

Oh, yes. Old-time waltzes. Yes. I don't agree with the old-time waltz
at all. Nothing wrong with it, of course, Mr. Connors, nothing actually
wrong ; with it...

Address the Chair, address the Chair, said my uncle.

But after all a Ceilidhe is not the place for it, that's all. A Ceilidhe is a Ceilidhe. I mean, we have our own. We have plenty of our own dances without crossing the road to borrow what we can't wear. See the point? It's all right but it's not for us. Leave the waltz to the jazz-boys. By God they're welcome to it as far as I'm concerned.

Mr. Connors to reply, said my uncle.

Oh, settle it any way you like, said Mr. Connors. It was only a suggestion. There's nothing wrong with the old-time waltz. Nothing in the wide world. I've danced it myself. Mr. Hickey here has danced it. We have all danced it. Because a thing is foreign it does not stand to reason that it's bad.

When did I dance it? asked Mr. Hickey.

Description of Mr. Hickey: ; Old, yellow, dark, lean. Pendulous of flesh at eyes and jaws. Of utterance precise and slow. A watching listener.

On a Point of Personal Explanation? Asked my uncle.

Yes, said Mr. Hickey.

Very well.

Twenty-three years ago at the Rotunda Gardens, said Mr. Connors. Haven't I a good memory?

You certainly have, said Mr. Hickey.

He smiled, mollified. He pursed his lips in the exercise of a retrospect across the years, absently playing with his loose plates. Bushy brows hid his eyes as they gazed on his white knuckles.

Mr. Fogarty? ; said my uncle.

Mr. Fogarty was a middle-aged man but with a round satisfied face.

He smiled evenly on the company. He was attired in good-quality expensive clothing and wore an air of assurance. Settle it between ye, he said lightly. Leave Mr. Fogarty alone now. The Gaelic League is opposed to the old-time waltz, said Mr. Corcoran.

So are the clergy.

Now, now, I don't think that's right, said my uncle.

I never heard that said, said Mr. Connors. Which of the clergy now?

My uncle gave another crack.

Order, he repeated. Order.

Chapter and verse, Mr. Corcoran. Which of the clergy.

That will do, Mr. Connors, said my uncle sharply, that is quite sufficient.

This is a Committee Meeting. We won't be long settling it. All those in favour of the old-time waltz say Aye.

Aye!

Those against say No.

No!

I declare the Noes have it.

Division, called Mr. Connors.

A division has been challenged. I appoint Mr. Secretary teller. All those in favour raise one hand.

The total that I counted in favour of each proposition was one; certain parties abstained from voting.

My casting vote, said my uncle loudly, is in favour of the negative.

Well that's that, said Mr. Connors sighing.

If things are done in the right way, said my uncle, there is no question of wasting time. Now when does he arrive? You have the details, Mr. Hickey.

Mr. Hickey bestirred himself with reluctance and said: He will be in Cork from the liner at ten, that means Kingsbridge about seven.

Very well, said my uncle, that means he reaches the hall about nine, making allowance for a wash and a bite to eat. By nine o'clock -yes, we will be in full swing by nine o'clock. Now a Reception Committee. I appoint Mr. Corcoran and Mr. Connors and myself.

I still challenge Mr. Corcoran to give the name of the clergyman, said Mr. Connors.

That is not a Point of Order, said my uncle. He turned to me and said: Have you the names of the Reception Committee?

Yes, I answered.

Very good. Now I think I should read a brief address when he is at the door. Something short and to the point. A few words in Irish first, of course.

Oh, certainly, said Mr. Corcoran. Not forgetting a red carpet on the steps. That is the recognized thing. My uncle frowned.

Well I don't know, he said. I think a red carpet would be a bit... I agree with you, said Mr. Hickey.

A bit, you know... well, a little bit...

I see your point, I see your point, said Mr. Corcoran.

You understand me? We don't want to be too formal. After all he is one of our own, an exile home from the foreign clime.

Yes, he might not like it, said Mr. Corcoran.

It was quite proper, of course, to raise the point if it was on your mind, said my uncle. Well that is settled. Just a friendly Irish welcome, céad míle fáilte. Now there is another important matter that we'll need to see to. I refer to the inner man. The honourable secretary
will now read out the estimate of what we want. The secretary has the floor.

In my thin voice I enunciated the contents of the black note-book given
over to my charge.

I think you might put down another bottle and a couple of dozen stout,
said Mr. Hickey. I don't think they would go to waste.

Oh, Lord, yes, said Mr. Fogarty. We would want that.

I don't think he touches anything, said my uncle. A very strict man, I believe.

Well, of course, there are others, said Mr. Hickey sharply.

Who? Asked my uncle.

Who! Well dear knows! said Mr. Hickey testily.

Mr. Fogarty gave a loud laugh in the tense air.

Oh, put it down, Mr. Chairman, he laughed. Put it down man. A few of
us would like a bottle of stout and we might have friends in. Put it down
and say no more about it.

Oh, very well, said my uncle. Very good, very good.

I entered these further items in my ledger. Oh, talking about the clergy - on a Point of Order, Mr. Chairman - talking about the clergy, said Mr. Connors suddenly, I heard a good one the other day. A P.P. down in the County Meath.

Now, Mr. Connors, please remember that your audience is a mixed one, said my uncle severely.

It's all right now, said Mr. Connors smiling in reassurance. He invited two young priests down to the house for dinner. Two young priests from Clongowes or somewhere, you understand, smart boys, doctors and all the rest of it. Well the three of them went in to dinner and here were two fine fat chickens on the table. Two chickens for the three of them.

Fair enough, said Mr. Fogarty.

No disrespect, warned my uncle.

It's all right, said Mr. Connors. Just when they were sitting down for a good tuck-in, the P.P. gets a sick call and away with him on his white horse after telling the two visitors to eat away and not to mind him or to wait for him.

Fair enough, said Mr. Fogarty.

Well after an hour back came his reverence the P.P. to find a heap of bones on the plate there. Not a pick of the two chickens left for him.

He had to swallow his anger for damn the thing else was there left for him to swallow.

Well dear knows they were the nice pair of curates, said my uncle in facetious consternation.

Weren't they, said Mr. Connors. Well after a bit the two said they were very full and would like to stretch their legs. So out went the three of them to the farm-yard. It was summer, you understand.

Fair enough, said Mr. Fogarty.

Over comes the P.P.'s cock, a grand big animal with feathers of every colour in his tail. Isn't that the fine proud cock you have, says one of the curates. The P.P. turns and looks at him. _And why wouldn't he be proud, says he, and him with two sons in the Jesuits!_

Oh, fair enough, roared Mr. Fogarty laughing.

There was general acclamation and amusement in which I inserted perfunctorily my low laugh.

Isn't it good? laughed Mr. Connors. The P.P. turns on the curate and looks him in the eye. And why wouldn't he be proud, says he, and him with two sons in the  _Jesuits_!

Very good indeed, said my uncle. Now we want three clean respectable women to cut the bread.

Let me see, said Mr. Corcoran. You have Mrs. Hanafin and Mrs. Corky.

Poor of course, but good clean respectable women.

Cleanliness is all-important, said my uncle. Lord save us, thumb-marks on bread, there is nothing so disgusting. Are they clean, Mr. Corcoran?

Oh, very clean and respectable.

Very good, said my uncle, we will leave that to you.

He put up the four fingers of a hand in the air and identified by a finger the cares of each member of the assembly.

Sandwiches and refreshments Mr. Corcoran, he said. Mr. Hickey is attending to the band and will oblige in the interval. Mr. Fogarty will be M. C. and will also oblige with a hornpipe. I will look after our friend. That is everything, I think. Any questions, gentlemen?

A vote of thanks to our efficient young secretary, said Mr. Fogarty smiling.

Oh, certainly, said my uncle. Passed unanimously. Nothing else?

No.

Very good. I declare the meeting adjourned seeny day.

Conclusion of reminiscence.

----------
Publication information:

At Swim-Two Birds, by Flann O'Brien,

Copyright Brian O'Nolan 1939, 1960. Copyright Evelyn O'Nolan 1976.

First published in Great Britain 1939

Reissued by MacGibbon &amp; Kee 1960

Reprinted 1961, 1966

Reissued by Hart-Davis, MacGibbon Ltd 1976

Reissued by Granada Publishing 1982.
`;

const AtSwimTwoBirds = () => {
	return (
		<div className="archive">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					Brooks Academy - At Swim Two Birds
				</title>
			</Helmet>
			<h1>
				<Breadcrumb>
					<LinkContainer to="/archive">
						<Breadcrumb.Item>Archive</Breadcrumb.Item>
					</LinkContainer>
					<Breadcrumb.Item active>
						At Swim Two Birds
					</Breadcrumb.Item>
				</Breadcrumb>
			</h1>

			<div className="box-content box">
				<ReactMarkdown>
					{article}
				</ReactMarkdown>
			</div>
		</div>
	);
};

export default AtSwimTwoBirds;
